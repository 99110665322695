import React from 'react';
import { navigate } from 'gatsby';
import Cookies from 'js-cookie';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';
import Button from '@material-ui/core/Button';
import Loading from './Alerts/Loading';
import ErrorAlert from './Alerts/ErrorAlert';

class CheckoutForm extends React.Component {
  handleSubmit = async event => {
    // Block native form submission.
    event.preventDefault();
    this.setState({
      disabledSubmit: true,
      pendingPayment: true,
      paymentFailed: false,
    });
    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    const card = elements.getElement(CardElement);

    const result = await stripe.createToken(card);

    if (result.error) {
      // Inform the user if there was an error.
      this.setState({
        disabledSubmit: false,
        pendingPayment: false,
        paymentFailed: true,
      });
    } else {
      // Send the token to your server.
      console.log(result);
      this.submitEIN(result.token.id)
    }
  };

  state = {
    disabledSubmit: false,
    pendingPayment: false,
    paymentFailed: false,
    address2: '',
  };

  submitEIN = token => {
    this.setState({ pendingPayment: true, paymentFailed: false });
    try {
      if (Cookies.get('addressLine2') === 'undefined') {
        this.setState({ address2: '' });
      } else {
        this.setState({ address2: Cookies.get('addressLine2') });
      }
      fetch(`${process.env.GATSBY_API_URL}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          stripeToken: token,
          email: Cookies.get('email'),
          first_name: Cookies.get('firstName'),
          last_name: Cookies.get('lastName'),
          phone_number: Cookies.get('phone'),
          ssn: Cookies.get('ssn'),
          address_line1: Cookies.get('addressLine1'),
          address_line2: this.state.address2,
          city: Cookies.get('city'),
          state: Cookies.get('state'),
          zip_code: Cookies.get('zipcode'),
          reason_for_filing: Cookies.get('filingReason'),
          entity_name: Cookies.get('entityName'),
          filed_state: Cookies.get('entityState'),
          entity_type: Cookies.get('entityType'),
          number_of_members: Cookies.get('membersNumber'),
          entity_category: Cookies.get('businessCategory'),
        }),
      })
        .then(res => res.json())
        .then(res => {
          this.setState({ pendingPayment: false, paymentFailed: false });
          if (res.REPORT === 'payment error occurred') {
            this.setState({
              pendingPayment: false,
              paymentFailed: true,
              disabledSubmit: false,
            });
          }
          if (
            res.STATUS === 'ERROR' &&
            res.REPORT !== 'payment error occurred'
          ) {
            navigate('/get-ein//ein-fail');
          }
          if (res.STATUS === 'SUCCESS') {
            navigate('/get-ein//ein-success', {
              state: {
                entityName: res.DATA.entityName,
                einNumber: res.DATA.einNumber,
                einLetterURL: res.DATA.einLetterURL,
              },
            });
          }
        });
    } catch (err) {
      console.log(err);
      this.setState({
        pendingPayment: false,
        paymentFailed: false,
        disabledSubmit: false,
      });
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <form onSubmit={this.handleSubmit}>
        <div style={{ width: '300px' }}>
          <br />
          <Loading visible={this.state.pendingPayment}>Processing</Loading>
          <ErrorAlert visible={this.state.paymentFailed}>
            Card Declined
          </ErrorAlert>
          <br />
          <CardElement style={{ base: { fontSize: '18px' }, width: '400px' }} />
          <br />
          <br />
        </div>
        <Button
          disabled={this.state.disabledSubmit}
          type="submit"
          variant="contained"
          color="primary"
          size="large"
          buttonstyle={{ borderRadius: 25 }}
          style={{
            borderRadius: 25,
            textTransform: 'none',
            width: 300,
            alignItems: 'center',
            justify: 'center',
          }}
        >
          Submit
        </Button>
      </form>
    );
  }
}

const InjectedCheckoutForm = () => {
  return (
    <ElementsConsumer>
      {({ elements, stripe }) => (
        <CheckoutForm elements={elements} stripe={stripe} />
      )}
    </ElementsConsumer>
  );
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_KEY);
const CheckoutFormSubmit = () => {
  return (
    <Elements stripe={stripePromise}>
      <InjectedCheckoutForm />
    </Elements>
  );
};

export default CheckoutFormSubmit;
